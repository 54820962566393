import * as $ from 'jquery/dist/jquery.js';
// console.log($);

var RPCAPI = 'https://stopper.borobudurmarathon.com/dev.titudev.com/api/';

// var _User = (function () {
// 	var qs = function(obj, prefix){
// 		var str = [];
// 		for (var p in obj) {
// 			var k = prefix ? prefix + "[" + p + "]" : p, 
// 			v = obj[p];
// 			str.push(typeof(v) == 'object' ? qs(v, k) : (k) + "=" + encodeURIComponent(v));
// 		}
// 		return str.join("&");
// 	}

// 	function login(credential) {
// 		console.log('Login user called...', credential);
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v1/login',
// 			data: JSON.stringify({data:credential}),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json',
// 			crossDomain: true
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	function checkSession(credential) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: RPCAPI + 'v1/check',
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err){
// 				window.location.href = '/signin';
// 			}
// 		});
// 	}
// 	function setEvent(eventId) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: RPCAPI + 'v1/userevent/' + eventId
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	// http://dev.titudev.com/api/v2/rpc_pack/check/INFILTROMETERCF7/20438

// 	function rpc_picklist(param) {
// 		var _url = RPCAPI + 'v1/resources/rpc_data';
// 		if (param){
// 			_url = _url + '?' + qs(param);
// 		}
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: _url,
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				// toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function rpc_pickingLocation(param) {
// 		var _url = RPCAPI + 'v1/resources/bib_location?&pageSize=25';
// 		if (param){
// 			_url = _url + '?' + qs(param);
// 		}
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: _url,
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function rpc_status(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v2/rpc_pack/check/'+data.transactionID+'/'+data.BIB,
// 			data: JSON.stringify(data),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	/*POST : /api/v2/rpc_verify/{refid}
// 	* harus udah login

// 	Data : 
// 	{"data":{"rpcbVerificationCode":"CODE VERIFIKASI","rpcbVerificationDescription":"NAMA ORANG YANG AMBIL"}}*/

// 	function verify(eventId) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: RPCAPI + 'v1/userevent/' + eventId,
// 			crossDomain: true
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function rpc_verify(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v2/prevalidation/'+data.data.participantID,
// 			data: JSON.stringify(data),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function rpc_pickup(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v2/rpc_pickup/'+data.data.registrationID,
// 			data: JSON.stringify(data),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function rpc_jersey(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v2/rpc_jersey/'+data.data.registrationID,
// 			data: JSON.stringify(data),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function validate(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: verificationAPI + 'validate',
// 			data: data
// 			// contentType: 'application/json; charset=utf-8',
// 			// dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}

// 	function doExtract(data){
// 		return $.post("https://validator.race.id/extract/image",data
			
// 		).catch(function(err) {
// 			toastr.error('Ooops... ' + err);
// 		});
// 	}

// 	function verifyByBIB(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: verificationAPI + 'verifyByBIB',
// 			data: data
// 			// contentType: 'application/json; charset=utf-8',
// 			// dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	function submitUri(uri){
// 		var blob = dataURItoBlob(uri);
// 		var fd = new FormData();
// 		fd.append("file", blob, new Date().getTime()+".jpg");
// 		return $.ajax({
// 			url: "https://validator.race.id/upload", // Url to which the request is send
// 			type: "POST",             // Type of request to be send, called as method
// 			data: fd, // Data sent to server, a set of key/value pairs (i.e. form fields and values)
// 			contentType: false,       // The content type used when sending data to the server.
// 			cache: false,             // To unable request pages to be cached
// 			processData:false
// 		}).catch(function(err) {
// 			toastr.error('Ooops... ' + err);
// 		});
// 	}

// 	// add on rpc delegation
// 	function rpc_do_delegate(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v1/rpc_pack/delegation/'+data.refid,
// 			data: JSON.stringify({data: data}),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	function rpc_do_confirm_delegation(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "POST",
// 			url: RPCAPI + 'v1/rpc_pack/delegation_confrim/'+data.token,
// 			data: JSON.stringify({data: data}),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	function rpc_do_cancel_delegation(data) {
// 		return $.ajax({
// 			xhrFields: {
// 				withCredentials: true
// 			},
// 			type: "GET",
// 			url: RPCAPI + 'v1/rpc_pack/delegation_cancel/'+data.token,
// 			data: JSON.stringify({data: data}),
// 			contentType: 'application/json; charset=utf-8',
// 			dataType: 'json'
// 		}).catch(function(err) {
// 			if (err&&err.responseJSON){
// 				toastr.error('Ooops... ' + err.responseJSON.status.error.message);
// 			}
// 		});
// 	}
// 	// end add on rpc delegation

// 	return {
// 		login: login,
// 		setEvent: setEvent,
// 		verifyByBIB : verifyByBIB,
// 		submitUri : submitUri,
// 		rpcVerify : rpc_verify,
// 		rpcStatus : rpc_status,
// 		rpcPickup : rpc_pickup,
// 		rpcPickList : rpc_picklist,
// 		rpcJersey : rpc_jersey,
// 		validate : validate,
// 		checkSession : checkSession,
// 		rpcPickingLocation : rpc_pickingLocation,
// 		doExtract : doExtract,
// 		// add on rpc delegation
// 		rpcDoDelegate : rpc_do_delegate,
// 		rpcConfirmDelegation : rpc_do_confirm_delegation,
// 		rpcCancelDelegation : rpc_do_cancel_delegation
// 	}
// })();

// export const Login = function(credential) {
//     console.log('Login user called...', credential);
//     return $.ajax({
//         xhrFields: {
//             withCredentials: true
//         },
//         type: "POST",
//         url: RPCAPI + 'v1/login',
//         data: JSON.stringify({data:credential}),
//         contentType: 'application/json; charset=utf-8',
//         dataType: 'json',
//         crossDomain: true
//     }).catch(function(err) {
//         // if (err&&err.responseJSON){
//         //     toastr.error('Ooops... ' + err.responseJSON.status.error.message);
//         // }
//     });
// }


export const Login = async (credential) => {
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        type: "POST",
        url: RPCAPI + 'v1/login',
        data: JSON.stringify({data:credential}),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        crossDomain: true
    })
};
  
export const CheckSession = async () => {
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        type: "GET",
        url: RPCAPI + 'v1/check',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
    })
}

export const SetEvent = async (eventId) => {
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        type: "GET",
        url: RPCAPI + 'v1/userevent/' + eventId
    })
}

export const TransactionCheck = async (transactionID) => {
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        type: "GET",
        url: RPCAPI + 'v1/rpc_pack/info_check/' + transactionID
    })
}
